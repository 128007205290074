
import React, { useState } from 'react'

import AccordionSection from './CustomAccordion';

function App() {
  const [activeAccordion, setActiveAccordion] = useState(null)
  const toggleAccordion = (eventKey) => {
    setActiveAccordion(activeAccordion === eventKey ? null : eventKey)
  }
  const data = [
    {
      "title": "Inovação",
      "description": "A inovação é o valor do século XXI e a criatividade é base para oferecer um ensino atual, inspirador e, sobretudo, transformador. Para inovar é necessário estimular o pensamento crítico, a originalidade e uma cultura de resolução de problemas.",
    },
    {
      "title": "Hands on",
      "description": "As pessoas devem ser capazes de resolver problemas usando os próprios recursos. É nosso dever criar espaços de aprendizagem que privilegiem atividades práticas, projetos desafiadores que usem tecnologia de ponta, mas que também exijam habilidades como montar, construir, consertar e manusear objetos dos mais variados colocando a mão na massa.",
    },
    {
      "title": "Gestão de talentos",
      "description": "A matéria-prima da escola é o talento. É nosso dever investir no potencial e na vocação de nossos alunos, zelando para que adquiram competências excepcionais que os façam progredir emocional e academicamente.",
    },
    {
      "title": "Multiculturalismo",
      "description": "O Bert é uma escola internacional, não estrangeira. Nossos alunos são brasileiros com cidadania global. Trata-se de uma marca diversa, multicultural e global por natureza. “Celebre a diversidade, alimente-se do ambiente inclusivo, enriqueça sua formação e obtenha cidadania global”.",
    },
    {
      "title": "Excelência",
      "description": "Excelência acadêmica por princípio. Desafios globais vão exigir competências, habilidades e posturas que nossos alunos precisam adquirir a partir do contato com um currículo forte e inovador.",
    },
  ];

  return (
    <div className="App">
      <div className="content">
          {data.map((item, index) => {
            return (
              <AccordionSection key={index}
                eventKey={index.toString()}
                callback={toggleAccordion}
                isOpen={activeAccordion === index.toString()}
                title={item.title}
                >
                {item.description}
              </AccordionSection>
            )
          })}
      </div>
    </div>
  );
}

export default App;

