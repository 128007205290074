import { css } from '@emotion/react'

export const turmasHome = css`
  padding: 80px 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #EFEFEF;
  scroll-margin-top: 117px;
  overflow:hidden ;
  .itemContent {
    h3 {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
      color: #312F2D;
      :not(:last-child) {
        margin-bottom: 16px;
      }
    }
    p {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: #312F2D;
      :not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
  .descricao-turma {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
  }
  .DDesktop {
    @media (min-width: 320px) and (max-width: 767px) {
      display: none;
    }

    @media (min-width: 481px) and (max-width: 767px) {
      display: none;
    }

    @media (min-width: 768px) and (max-width: 990px) {
      display: none;
    }

    @media (min-width: 991px) and (max-width: 1199px) {
      display: flex;
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
      display: flex;
    }
  }
  .DMobile {
    @media (min-width: 320px) and (max-width: 767px) {
      display: flex;
    }

    @media (min-width: 481px) and (max-width: 767px) {
      display: flex;
    }

    @media (min-width: 768px) and (max-width: 990px) {
      display: flex;
    }

    @media (min-width: 991px) and (max-width: 1199px) {
      display: none;
    }

    @media (min-width: 1200px) and (max-width: 3600px) {
      display: none;
    }
  }
`
