
import React, { useState } from 'react'

import {
  AccordionItem,
  AccordionWrapper,
} from 'custom-react-accordion'
import AccordionSection from './CustomAccordion'

function App() {
  const [activeAccordion, setActiveAccordion] = useState(null)
  const toggleAccordion = (eventKey) => {
    setActiveAccordion(activeAccordion === eventKey ? null : eventKey)
  }
  const data = [
    {
      "title": "Expansão global",
      "description": "A linha de frente de serviços e todos os principais pontos de contato da escola compartilham da mesma visão global. Proporcionamos experiências, promovemos iniciativas e realizamos parcerias com instituições internacionais de renome para fortalecer e aproximar a comunidade acadêmica desta visão de crescimento. “Abra-se para novas culturas, perspectivas e horizontes, construindo conexões que transcenderão fronteiras”."
    },
    {
      "title": "Parcerias estratégicas",
      "description": "É preciso estabelecer parcerias estratégicas com organizações, setores, instituições de ensino, programas de fomento a startups nacionais e internacionais que possam colocar a escola no centro das decisões e, ao mesmo tempo, ampliar as possibilidades de enriquecer a experiência e a visão de nossos alunos."
    },
    {
      "title": "Visão de futuro",
      "description": "A única certeza no futuro é que as coisas vão mudar - e cada vez mais rápido. O aluno que acaba de entrar em nosso ensino infantil, começou a se preparar para exercer uma profissão que pode ainda nem ter sido criada. É por isso que devemos aperfeiçoar nossas práticas e, sempre que possível, incorporar tecnologias emergentes ao nosso dia a dia."
    },
    {
      "title": "Orientação para resultados",
      "description": "Resultados consagram métodos. A escola deve orientar e direcionar pais, alunos e a comunidade a alcançarem sempre os melhores resultados em aprovação, independente se a instituição é nacional ou internacional.",
    },
    {
      "title": "Colaboração Transdisciplinar",
      "description": "Produzir conhecimento através da mera combinação entre ciência, arte, tecnologia e raciocínio lógico não é mais suficiente. É preciso combinar conceitos comuns de cada área, experimentar novas linguagens e incentivar o trabalho em equipe. É do choque entre o conhecimento e a realidade e da troca de perspectivas entre os alunos que surge a verdadeira inovação."
    },
  ];

  return (
    <div className="App">
      <div className="content">
      {data.map((item, index) => {
            return (
              <AccordionSection key={index}
                eventKey={index.toString()}
                callback={toggleAccordion}
                isOpen={activeAccordion === index.toString()}
                title={item.title}
                >
                {item.description}
              </AccordionSection>
            )
          })}
      </div>
    </div>
  );
}

export default App;