/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import MaskInput from 'react-awesome-mask-input';

import { 
  contatosEVisitas,
  btnEnviar
}  from '../assets/styles/ExcelenciaInternacional.styles'

const Contatos = () => {
  return (
    <Fragment>
      <section css={contatosEVisitas} id="contatos">
        <Container>
          <Row>
            <Col lg={1} md={1} sm={12} />
            <Col lg={4} md={4} sm={12}>
              <div className="MB-120">
                <h3>VISITA</h3>
                <p>Vamos conversar. Agende abaixo o melhor dia e horário para uma visita.</p>
                <form
                  name='agendar-visita'
                  method='POST'
                  action='/sucesso'
                  data-netlify='true'
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='agendar-visita'
                  />
                  <p>
                    <input 
                      type='text' 
                      name='nome' 
                      placeholder='Nome' 
                      required 
                    />
                  </p>
                  <p>
                    <MaskInput
                      type="text"
                      mask={['(99) 9999-9999', '(99) 9 9999-9999']}
                      placeholder="Telefone"
                      name='telefone'
                    />
                  </p>
                  <p>
                    <input
                      type='datetime-local'
                      name='data-visita'
                      placeholder='Escolha uma hora'
                      required
                    />
                  </p>
                  <p>
                    <input
                      type='text'
                      name='idade filhos'
                      placeholder='Idade dos filhos'
                      required
                    />
                  </p>
                  <button css={btnEnviar} type='submit'>
                    Enviar
                  </button>
                </form>
              </div>
            </Col>
            <Col lg={2} md={2} sm={12} />
            <Col lg={4} md={4} sm={12}>
              <div>
                <h3>CONTATO</h3>
                <p>Fale com nosso time de relacionamento se quiser mais informações.</p>
                <form
                  name='contatos'
                  method='POST'
                  action='/sucesso'
                  data-netlify='true'
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='contatos'
                  />
                  <p>
                    <input 
                      type='text' 
                      name='name' 
                      placeholder='Nome' 
                      required 
                    />
                  </p>
                  <p>
                    <MaskInput
                      type="text"
                      mask={['(99) 9999-9999', '(99) 9 9999-9999']}
                      placeholder="Telefone"
                      name='telefone'
                    />
                  </p>
                  <p>
                    <input
                      type='mail'
                      name='email'
                      placeholder='E-mail'
                      required
                    />
                  </p>
                  <p>
                    <input 
                      type='text' 
                      name='mensagem' 
                      placeholder='Mensagem' 
                      required 
                    />
                  </p>
                  <button css={btnEnviar} type='submit'>
                    Enviar
                  </button>
                </form>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default Contatos;
