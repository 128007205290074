/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import { Link } from "gatsby"
import ImgDiferencial from "@/images/lp/home/o-essencial-e-diferencial.png"

import { 
  oDiferencial,
}  from '../assets/styles/ExcelenciaInternacional.styles'

const EssencialDiferencial = () => {
  return (
    <Fragment>
      <section css={oDiferencial}>
        <Container>
          <Row>
            <Col lg={1} md={1} sm={12} />
            <Col lg={7} md={7} sm={12} data-aos-delay={"1000"} data-aos="fade-right">
              <h2><span>O essencial é diferencial.</span></h2>
              <img src={ImgDiferencial} alt='/' />
            </Col>
            <Col lg={4} md={6} sm={12} data-aos-delay={"1000"} data-aos="fade-left">
              <p>Toda atenção, tempo e investimento necessários à formação dos alunos precisam servir para melhorar também a experiência e o relacionamento com os pais e responsáveis.</p>
              <div className="justifyCenter">
                <Link to='/essencial-para-os-pais'>ACESSE E SAIBA MAIS</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default EssencialDiferencial;
