import React from "react";
import Layout from "@/components/layout";
import MenuHeader from "@/components/header-one";
import SliderOne from "@/components/slider-two";
import LideresGlobais from "@/components/LideresGlobaisHome";
import PrincipiosOrientadores from "@/components/PrincipiosOrientadores";
import ExecelenciaInternacional from "@/components/ExcelenciaInternacional";
import Diferenciais from "@/components/DiferenciaisHome";
import BertKids from "@/components/BertKids";
import Turmas from "@/components/TurmasHome";
import OEssencialDiferencial from "@/components/OEssencialDiferencial";
import Contatos from "@/components/Contatos";
import Footer from "@/components/footer";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";


const Index = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Home">
          <MenuHeader />
          <SliderOne />
          <LideresGlobais />
          <PrincipiosOrientadores />
          <ExecelenciaInternacional />
          <Diferenciais />
          <BertKids />
          <Turmas />
          <OEssencialDiferencial />
          <Contatos />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Index;
