/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import IcoArrow from "@/images/lp/icons/arrow-b.svg"
import IcoCiaTeatro from "@/images/lp/icons/cia-teatro.svg"
import IcoFabLab from "@/images/lp/icons/fab-lab.svg"
import IcoStrike from "@/images/lp/icons/strike.svg"

import { 
  diferenciais,
}  from '../assets/styles/DiferenciaisHome.styles'
import { Link } from "gatsby"

const DiferenciaisHome = () => {
  return (
    <Fragment>
      <section css={diferenciais} id="diferenciais">
        <Container>
          <Row className="mb-56">
            <Col lg={3} md={3} sm={12} />
            <Col lg={1} md={1} sm={12}>
              <div className="textCenter">
                <img src={IcoCiaTeatro} alt='' />
              </div>
            </Col>
            <Col lg={8} md={8} sm={12}>
              <div>
                <Link to='/atividades-adicionais'>CIA DE TEATRO BERTONI <img src={IcoArrow} alt='' /></Link>
                <p>Encenar clássicos do teatro enquanto desenvolve competências como empreendedorismo, colaboração e autoconfiança. Ensaiar, produzir e apresentar espetáculos como exercício de liderança e autogestão. Be Bert, Be Global. There is no question!</p>
              </div>
            </Col>
          </Row>
          <Row className="mb-56">
            <Col lg={3} md={3} sm={12} />
            <Col lg={1} md={1} sm={12}>
              <div className="textCenter">
                <img src={IcoFabLab} alt='' />
              </div>
            </Col>
            <Col lg={8} md={8} sm={12}>
              <div>
                <Link to='/atividades-adicionais'>Digital FabLab <img src={IcoArrow} alt='' /></Link>
                <p>Desenvolver projetos para enfrentar desafios da nova economia digital. No Bert, você tem um laboratório de fabricação digital inspirado no MIT - Instituto de Tecnologia de Massachusetts (USA) - espaço pensado para articular competências das áreas de tecnologia, engenharia, artes e design.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={3} sm={12} />
            <Col lg={1} md={1} sm={12}>
              <div className="textCenter">
                <img src={IcoStrike} alt='' />
              </div>
            </Col>
            <Col lg={8} md={8} sm={12}>
              <div>
                <Link to='/atividades-adicionais'>Strike - Equipe de Robótica <img src={IcoArrow} alt='' /></Link>
                <p>DUAS VEZES CAMPEÃ DO MUNDO. <br />
                A Strike 6902 é a equipe de robótica formada por alunos do Bert. De Montreal a Istambul, os jovens que formam a Strike aprendem lições que vão além da tecnologia e passam pelo design, marketing e gestão, ao mesmo tempo em que promovem intenso intercâmbio com diferentes culturas nas viagens que realizam pelo mundo.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default DiferenciaisHome;
