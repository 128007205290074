import React from 'react'

const ArrowDown = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 27" fill="none">
            <path d="M27.4766 13.1526L15.5725 25.0567C15.2366 25.3926 14.6958 25.3926 14.3656 25.0567L2.46159 13.1526" stroke={color ? color : "#312F2D"} stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" />
            <path d="M15 23L15 2" stroke={color ? color : "#312F2D"} stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" />
        </svg>
    )
}

export default ArrowDown
