import React from 'react'
import { Col, Row } from "react-bootstrap";
import 'react-accessible-accordion/dist/fancy-example.css'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import ImgKids from "@/images/lp/home/kids.png"
import ImgJr from "@/images/lp/home/junior.png"
import ImgTeens from "@/images/lp/home/teens.png"
import ImgHigh from "@/images/lp/home/high-school.png"

const TurmasHome = () => {
  return (
    <Accordion allowZeroExpanded>
      <Row>
        <AccordionItem uuid>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div>
                <div>Kids</div>
                <p className='descricao-turma'>de 1 a 5 anos</p>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Col lg={7} md={7} sm={12}>
              <div className='itemContent'>
                <img className='DMobile' style={{ marginBottom: 40 }} src={ImgKids} />
                <h3>Early Learning</h3>
                <p>A primeira infância é um desafio instigante para o educador. Entre educadores, autoridades de governos e fundações dedicadas ao tema, não há dúvidas quanto ao impacto positivo para a humanidade do chamado Early Learning - estimular a criança a aprender o quanto antes. O Bert Kids é uma unidade especialmente dedicada ao Ensino Infantil e Fundamental e lá todos os dias são oportunidades de semear nossa visão de que é possível criar um pólo global de excelência acadêmica se aproveitando da riqueza multicultural de nossa comunidade e de um sólido currículo escolar baseado em inovação e impacto.</p>
                <h3>Educação Infantil por Projetos</h3>
                <p>Formação guiada por metodologias inovadoras, muito utilizadas na Finlândia, que articulam conhecimentos das áreas de linguagem, ciência, artes e pensamento matemático.</p>
                <h3>Refeitório-Escola</h3>
                <p>Alimentação saudável é um valor que se aprende e se pratica em sala de aula. Nas nossas Cooking Classes, as crianças entendem, com a mão na massa, como é a alimentação em diversas culturas.</p>
              </div>
            </Col>
            <Col lg={5} md={5} sm={12}>
              <div className='text-right'>
                <img className='DDesktop' src={ImgKids} />
              </div>
            </Col>
          </AccordionItemPanel>
        </AccordionItem>
      </Row>
      <Row>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div>
                <div>JÚNIOR</div>
                <p className='descricao-turma'>de 6 a 11 anos</p>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Col lg={7} md={7} sm={12}>
              <div className='itemContent'>
                <img className='DMobile' style={{ marginBottom: 40 }} src={ImgJr} />
                <h3>Inventando o futuro</h3>
                <p>O período mais longo da vida escolar se inicia nesta fase. Os anos iniciais do Ensino Fundamental são importantes marcos de aprendizagem e são encarados pelo BERT como uma excelente oportunidade de aliar nossa visão de educação com impacto global à formação de talentos que aprendam desde cedo a lançar mão de conhecimentos existentes em todas as áreas. Acreditamos no estímulo à linguagem através da música, no desenvolvimento cognitivo ancorado em atividades físicas e na compreensão do mundo através do autoconhecimento e do fortalecimento dos vínculos socioemocionais com a comunidade.</p>
                <h3>Ensino Fundamental Bilíngue</h3>
                <p>Nossos alunos assistem a aulas em português e em inglês. O Bert Kids conta ainda com o Learning Center - currículo orientado ao exame do Cambridge Young Learners. Trata-se de uma formação completa em inglês que pode ser utilizada até por crianças de outras escolas que queiram complementar sua formação internacional.</p>
                <h3>Currículo internacional orientado pela AGENDA ONU 2030</h3>
                <p>Desde cedo os alunos aprendem a desenvolver competências ligadas aos principais desafios da humanidade. O Bert estrutura todo o currículo em torno da Agenda ONU 2030.</p>
                <h3>Programa SocioEmocional</h3>
                <p>As habilidades socioemocionais são trabalhadas desde as atividades pré-escolar e se acompanham o aluno até o Ensino Médio.</p>
              </div>
            </Col>
            <Col lg={5} md={5} sm={12}>
              <div className='text-right'>
                <img className='DDesktop' src={ImgJr} />
              </div>
            </Col>
          </AccordionItemPanel>
        </AccordionItem>
      </Row>
      <Row>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div>
                <div>TEENS</div>
                <p className='descricao-turma'>de 12 a 14 anos</p>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Col lg={7} md={7} sm={12}>
              <div className='itemContent'>
                <img className='DMobile' style={{ marginBottom: 40 }} src={ImgTeens} />
                <h3>Transformadores em transformação</h3>
                <p>Os anos finais do Ensino Fundamental coincidem com alguns dos momentos mais singulares da vida e o Bert construiu ao longo do tempo experiências de aprendizagem que aprofundam o significado do conhecimento para jovens adolescentes. Para lidar com um estudante em plena transformação - social, emocional e cognitiva - nosso currículo internacional conta com avaliação externa de referência para oferecer programas de estudo cada vez mais personalizados. Antecipar alguns desafios, detectar a vocação e orientar os estudantes é fundamental. No Bert, já a partir do 6º ano, os alunos passam a ter aulas de Física e Química e acostumam-se a experimentar uma jornada por assuntos como Redação e Pensamento Matemático já com enfoque nos critérios de avaliação exigidos pelo ENEM.</p>
                <h3>Avaliação para diagnóstico de desenvolvimento do aluno</h3>
                <h3>Grupos de Estudo para Matemática e Linguagem</h3>
                <h3>Aulas de pensamento matemático na innovation Room</h3>
                <h3>Aulas de Física e Química a partir do 6º ano</h3>
                <h3>Oficinas de redação orientada ao ENEM</h3>
                <h3>Certificação internacional</h3>
                <h3>Digital Fablab</h3>
                <p>No período integral, atividades como o Digital Fablab, a Companhia de Teatro Bertoni e as aulas de alemão e espanhol ajudam o jovem a expandir o olhar, viver novas experiências e ampliar sua visão de mundo justamente em um momento decisivo de construção de suas identidades.</p>
              </div>
            </Col>
            <Col lg={5} md={5} sm={12}>
              <div className='text-right'>
                <img className='DDesktop' src={ImgTeens} />
              </div>
            </Col>
          </AccordionItemPanel>
        </AccordionItem>
      </Row>
      <Row>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div>
                <div>High School</div>
                <p className='descricao-turma'>de 15 a 17 anos</p>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Col lg={7} md={7} sm={12}>
              <div className='itemContent'>
                <img className='DMobile' style={{ marginBottom: 40 }} src={ImgHigh} />
                <h3>O mundo precisa dos Berts</h3>
                <p>Vivemos conectados a redes digitais que eliminaram fronteiras, encurtaram distâncias e facilitaram a produção de tudo, mas como conciliar essa era de abundância aos graves problemas de escassez de recursos?</p>
                <h3>3 em cada 4 profissões que existirão em 2030 ainda não foram sequer criadas.</h3>
                <h3>85 milhões de postos de trabalho serão extintos até 2025.</h3>
                <p>Os Berts têm currículo com dupla certificação: ensino médio brasileiro e o high school americano pela Secretaria de Educação do estado do Missouri. Mas o que difere os Berts de outros estudantes do Ensino Médio é a preparação, o senso de responsabilidade e a vontade de impactar o mundo com seu talento e competência. Junte-se a nós e venha experimentar educação de excelência do tipo que gera impacto e responde aos diferentes desafios que o futuro nos reserva. O mercado está mudando e vai exigir muito as relações de trabalho estão mudando toda hora e você será exigido por conhecimentos e habilidades que você nem imagina que tem. Neste ambiente em que só a mudança é certeza, o Bert tem o que você precisa para lidar com o futuro.</p>
                <h3>
                  • Transformação Digital<br />
                  • Energias Renováveis<br />
                  • Empreendedorismo e Ed. Financeira<br />
                  • Direitos Humanos<br />
                  • Comunicação e Mídias Sociais
                </h3>
              </div>
            </Col>
            <Col lg={5} md={5} sm={12}>
              <div className='text-right'>
                <img className='DDesktop' src={ImgHigh} />
              </div>
            </Col>
          </AccordionItemPanel>
        </AccordionItem>
      </Row>
  </Accordion>
  );
};

export default TurmasHome;