/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import ImgExcelencia from "@/images/lp/home/excelencia.png"
import ImgAcaoGlobal from "@/images/lp/home/acao-global.png"

import { 
  excelenciaInternacional,
  acaoGlobal,
}  from '../assets/styles/ExcelenciaInternacional.styles'

const ExcelenciaInternacional = () => {
  return (
    <Fragment>
      <section css={excelenciaInternacional}>
        <Container>
          <Row>
            <Col lg={1} md={1} sm={12} />
            <Col lg={7} md={7} sm={12} data-aos-delay={"700"} data-aos="fade-right">
              <h2><span>Excelência</span> internacional em rede</h2>
              <img src={ImgExcelencia} alt='/' />
            </Col>
            <Col lg={4} md={6} sm={12} data-aos-delay={"700"} data-aos="fade-left">
              <div className="content-center">
                <h3>Bert é uma escola integral multilíngue com currículo internacional de High School certificado pela <span>University of Missouri.</span></h3>
                <p>Ao despertar seu potencial único, nosso estudante expande horizontes para conquistar um futuro brilhante e impactar o mundo com suas habilidades socioemocionaise criativas acima da média, desenvolvidas em um ambiente em que o esforço produtivo é valorizado na resolução de problemas complexos.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={acaoGlobal}>
        <Container>
          <Row>
            <Col lg={1} md={1} sm={12} />
            <Col className="dp-end" lg={4} md={4} sm={12}>
              <h2>Ação local, impacto <span>global.</span></h2>
            </Col>
            <Col className="dp-end" lg={3} md={3} sm={12}>
              <img src={ImgAcaoGlobal} alt='/' />
            </Col>
            <Col lg={4} md={6} sm={12} data-aos-delay={"750"} data-aos="fade-left">
              <div className="acoes-dados">
                <h2><span>50+</span></h2>
                <h3>Melhores escolas do Brasil</h3>
                <p>Desempenho incomparável nos vestibulares das principais universidades públicas brasileiras e nos exames de admissão para universidades internacionais.</p>
              </div>
              <div className="acoes-dados">
                <h2><span>87%</span></h2>
                <h3>dos professores com mestrado ou doutorado</h3>
                <p>Os melhores talentos entre professores contribuem para a formação de alunos cada vez mais criativos e preparados.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default ExcelenciaInternacional;
