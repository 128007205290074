/** @jsx jsx */
import { Container } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import AccordionTurmas from "@/components/AccordionTurmas";

import { 
  turmasHome,
}  from '../assets/styles/Turmas.styles'

const TurmasHome = () => {
  return (
    <Fragment>
      <section css={turmasHome} id="turmas">
        <Container>
          <AccordionTurmas />
        </Container>
      </section>
    </Fragment>
    
  );
};

export default TurmasHome;
