/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import LogoBertKids from "@/images/lp/logo-bert-kids.svg"
import BgBertKids from "@/images/lp/home/bg-bert-kids-mobile.png"

import { 
  bertKidsHeader,
  DMobile,
}  from '../assets/styles/BertKids.styles'

const BertKids = () => {
  return (
    <Fragment>
      <section css={bertKidsHeader}>
        <Container>
          <Row>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <img src={LogoBertKids} alt='' />
              <p>Imaginação e criatividade são valores que são cultivados desde cedo. Nosso segmento Infantil segue metodologia inovadora ao posicionar o professor como tutor de grupos com 5 alunos cada. Cada grupo desenvolve projetos que ampliam o repertório do aluno, usando linguagem, artes, música e ciência.</p>
            </Col>
          </Row>
        </Container>
        <div className="text-center"><img css={DMobile} src={BgBertKids} alt='' /></div>
      </section>
    </Fragment>
    
  );
};

export default BertKids;
