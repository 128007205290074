import { css } from '@emotion/react'

export const principiosHeader = css`
  overflow: hidden;
  padding: 60px 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #EFEFEF;
  h2 {
    color: #33535E;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #312F2D;
    margin-bottom: 0px;
  }
  .subtitle {
    display: flex;
    align-items: end;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
  }
`

export const visaoMissao = css`
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #EFEFEF;
  padding: 80px 0;
  img {
    text-align: center;
  }
  h2 {
    color: #33535E;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom: 32px;
    margin-top: 32px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #312F2D;
    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    img {
      width: 100%;
    }
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      width: 100%;
    }
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      width: 100%;
    }
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
`