import { css } from '@emotion/react'
import BgBertKids from '../images/lp/home/bg-bert-kids.png'

export const bertKidsHeader = css`
  padding: 160px 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: url(${BgBertKids}) no-repeat center center / cover;
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #312F2D;
    margin-bottom: 0px;
    margin-top: 56px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-bottom: 0px;
    padding-top: 80px;
    background: #FFC501;
    p {
      margin-top: 24px;
      margin-bottom: 16px;
      text-align: center;
      color: #312F2D;
    }
    img {
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-top: 80px;
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 32px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 32px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`