/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import { Link } from "gatsby";
import ImgBert from "@/images/lp/home/bert-bert.png"

import { 
  lideresHeader,
  bertBert,
  DDesktop,
  DMobile,
}  from '../assets/styles/LideresHome.styles'

const LideresGlobaisHome = () => {
  return (
    <Fragment>
      <section css={lideresHeader}>
        <Container>
          <Row>
            <Col lg={1} md={6} sm={12} />
            <Col lg={7} md={6} sm={12} data-aos-delay={"300"} data-aos="fade-right">
              <h2>Líderes<br /> globais para um futuro brilhante</h2>
            </Col>
            <Col lg={4} md={6} sm={12} data-aos-delay={"300"} data-aos="fade-left">
              <p>No Bert International, preparamos nossos alunos para se destacarem nas melhores universidades do mundo e se tornarem agentes de transformação global. Formamos uma comunidade escolar que valoriza a inovação, a colaboração interdisciplinar e a diversidade cultural, proporcionando uma educação imersiva e de excelência.</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={bertBert}>
        <Container>
          {/* data-aos-delay={"400"} data-aos="zoom-in" */}
          <Row>
            <Col css={DDesktop} lg={7} md={6} sm={12}>
              <div style={{ display: 'flex' }}>
                <h1>BERT</h1>
                <img src={ImgBert} />
              </div>
            </Col>
            <Col css={DMobile} sm={12}>
              <div className="bertMobile">
                <h1>BERT</h1>
                <img src={ImgBert} />
                <h2>BER</h2>
              </div>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <h1 css={DDesktop} style={{ marginTop: 60 }}>BERT</h1>
              <p>Junte-se a nós! Deixe sua marca no mundo e carimbe o passaporte de cidadania global.</p>
              <div className="btn-agende">
                <Link to='/'>
                  AGENDE UMA VISITA
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='none'
                  >
                    <g clip-path='url(#clip0_984_545)'>
                      <path
                        d='M6.62904 1.54102L11.8371 6.74903C11.984 6.89598 11.984 7.13259 11.8371 7.27705L6.62904 12.4851'
                        stroke='#312F2D'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='square'
                      />
                      <path
                        d='M11.375 7L1.75 7'
                        stroke='#312F2D'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='square'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_984_545'>
                        <rect
                          width='14'
                          height='14'
                          fill='white'
                          transform='translate(14) rotate(90)'
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default LideresGlobaisHome;
