import { css } from '@emotion/react'

export const diferenciais = css`
  padding: 80px 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #EFEFEF;
  scroll-margin-top: 117px;
  a {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; 
    text-transform: uppercase;
    color: #312F2D;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
    transition: transform 2000ms ease,opacity 2000ms ease,color 500ms ease,background 500ms ease,-webkit-transform 2000ms ease;
    img {
      margin-left: 16px;
    }
    :hover {
      color: #C2F57E;
      text-decoration: none;
      img {
        filter: invert(80%) sepia(49%) saturate(718%) hue-rotate(35deg) brightness(105%) contrast(92%);
        }
    }
    :active {
      color: #33535E;
      img {
        filter: invert(27%) sepia(62%) saturate(4224%) hue-rotate(147deg) brightness(86%) contrast(94%);
      }
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #312F2D;
    margin-bottom: 0px;
  }
  .mb-56 {
    margin-bottom: 56px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 0px;
      }
    }
    p {
      text-align: center;
    }
    img {
      margin-left: 0px;
      margin-bottom: 8px;
    }
    .textCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 0px;
      }
    }
    p {
      text-align: center;
    }
    img {
      margin-left: 0px;
      margin-bottom: 8px;
    }
    .textCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 0px;
      }
    }
    p {
      text-align: center;
    }
    img {
      margin-left: 0px;
      margin-bottom: 8px;
    }
    .textCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const turmasHome = css`
  padding: 80px 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #EFEFEF;
  h2 {
    color: #312F2D;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0px;
    margin-left: auto;
  }
  .itemContent {
    display: flex;
    align-items: end;
    width: 100%;
    p {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0px;
      margin-left: auto;
    }
  }
  .boxContent {
    display: flex;
    background: #C2F57E;
  }
  .mb-56 {
    margin-bottom: 56px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 0px;
      }
    }
    p {
      text-align: center;
    }
    img {
      margin-left: 0px;
      margin-bottom: 8px;
    }
    .textCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 0px;
      }
    }
    p {
      text-align: center;
    }
    img {
      margin-left: 0px;
      margin-bottom: 8px;
    }
    .textCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-bottom: 0px;
      }
    }
    p {
      text-align: center;
    }
    img {
      margin-left: 0px;
      margin-bottom: 8px;
    }
    .textCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`